@import "./responsive.scss";

// variables
$colors: (primary: #0e9,
  red: #ff4136,
  blue: #0074d9,
  yellow: #ffdc00,
  orange: #ff851b);

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  border: none;
  user-select: none;
  border-radius: 6px;
  transition: all 400ms cubic-bezier(.25, .8, .25, 1);
  box-shadow: none;

  i {
    margin-right: 4px;
  }

  @include for-phone-only {
    .text {
      display: none;
    }
  }

  &.transparent {

    /* For each key in the map, create a css class */
    @each $name,
    $value in $colors {
      &.#{$name} {
        color: $value;

        &:hover {
          color: #fff;
          background-color: $value;
          opacity: 0.7;
        }
      }
    }
  }

  &.filled {
    color: #fff;

    @each $name,
    $value in $colors {
      &.#{$name} {
        background-color: $value;
      }
    }

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      opacity: 0.7;
    }
  }

  &.outlined {
    border: 1px solid;
    background-color: transparent;

    /* For each key in the map, create a css class */
    @each $name,
    $value in $colors {
      &.#{$name} {
        color: $value;

        &:hover {
          color: #fff;
          background-color: $value;
        }
      }
    }
  }
}

input {
  outline: none;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 8px;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Rubik';
  width: 200px;
  transition: all 400ms cubic-bezier(.25, .8, .25, 1);

  &:focus,
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-color: #fff;
  }
}


h1 {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin: 0px;
}

a {
  color: #000;
  text-decoration: none;
}

*::-webkit-scrollbar {
  width: 4px;

  // &-track {
  //   background-color: rgba(0, 0, 0, 0.1);
  // }

  &-thumb {
    background-color: rgba(0, 238, 119, 0.3);
    border-radius: 2px;
  }
}