@import '../../styles/responsive.scss';

#side-nav {
  grid-area: side-nav;

  padding: 32px 16px 32px 48px;

  @include for-phone-only {
    padding: 16px 8px 16px 8px;
    display: flex;
    justify-content: space-evenly;
  }

  .item {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 8px 16px;

    &:not(:first-child) {
      margin-top: 8px;
    }

    color: #444;
    border-radius: 8px;
    transition: all 500ms cubic-bezier(0.25, 0.8, 0.25, 1);

    @include for-phone-only {
      padding: 8px 10px;
      margin-top: 0px !important;
    }

    &.active {
      color: rgba(0, 0, 0, 0.8);
      background-color: rgba(0, 238, 119, 0.1);

      .label {
        font-weight: 600;
      }
    }

    &:not(.active):hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .label {
      margin-left: 8px;
      text-transform: uppercase;
      font-weight: 400;
    }
  }
}