@import "../../styles/responsive.scss";

.search-bar {
  position: fixed;
  bottom: 16px;
  width: calc(100% - 200px);

  @include for-phone-only {
    width: calc(100% - 40px);
  }

  i.search {
    position: absolute;
    top: 13px;
    left: 13px;
    color: rgba(0, 0, 0, 0.7);
  }

  i.send {
    position: absolute;
    top: 13px;
    right: 132px;
    color: #0074d9;
    border-radius: 4px;
    cursor: pointer;
  }

  input {
    outline: none;
    border: none;
    margin-right: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 40px;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Rubik';
    transition: all 400ms cubic-bezier(.25, .8, .25, 1);

    @include for-phone-only {
      width: calc(100% - 40px);
      // padding: 8px 0px;

      &::-webkit-input-placeholder {
        color: transparent;
      }

      &:active,
      &:focus {

        &::-webkit-input-placeholder {
          color: unset !important;
        }
      }
    }

    @include for-tablet-portrait-up {
      width: calc(100% - 200px);
    }


    &:focus,
    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-color: #fff;
    }
  }

}