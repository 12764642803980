.wall .note-list {
  height: calc(100vh - 180px);
}

.tag-detail .note-list {
  height: calc(100vh - 268px);
}

.note-list {

  overflow-y: scroll;
  padding-right: 8px;

  .date {
    margin: 16px 0px;
    text-align: center;
    font-weight: 200;
  }

  .date+.item {
    border-top: 1px solid #eee;
    padding-top: 8px;
  }

  .item {

    input,
    button {
      margin-left: 8px;
      z-index: 3;
    }

    display: flex;
    align-items: center;
    padding: 0px 16px 8px 16px;
    margin-bottom: 8px;

    justify-content: space-between;

    .details,
    .actions {
      display: flex;
      align-items: center;
    }

    .time {
      color: #b7b7b7;
      font-size: 12px;
    }

    .tag {
      margin-left: 8px;
      font-weight: 500;
      font-size: 12px;
      text-transform: lowercase;
      cursor: pointer;
    }

    i {
      opacity: 0;
      cursor: pointer;
      font-size: 20px;
      color: #999;

      &.clip {
        margin-right: 8px;
      }

      transition: all 300ms ease;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.edit:hover {
        color: #fc0;
      }

      &.delete:hover {
        color: #f07;
      }
    }

    .message {
      cursor: pointer;
      padding: 3px 6px;
      border-radius: 4px;

      &.link {
        color: #0074d9;
        text-decoration: underline;
      }
    }

    &:hover {
      .message:not(.link) {
        background-color: #ccc;
      }

      i {
        opacity: 1;

      }
    }

    .message {
      margin-left: 8px;
      transition: background-color 300ms ease;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}