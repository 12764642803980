#modal {
  .mask {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;

    .box {
      background-color: #fff;
      width: fit-content;
      padding: 16px;
      border-radius: 8px;
      max-width: 50%;

      .header {
        font-weight: bold;
        font-size: 18px;
      }

      .header,
      .content {
        padding-bottom: 8px;
        border-bottom: 1px solid #eee;
      }

      .content {
        padding: 16px;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 8px;

        >*:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}