#email-confirmation {
  .resend {
    font-size: 11px;

  }

  .message {
    margin-top: 16px;
    font-weight: 300;
    color: #777;
    text-align: center;
  }
}