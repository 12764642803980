.empty-state {
  text-align: center;

  .label {
    margin-top: 8px;
    font-weight: 200;
    font-size: 16px;
    color: #aaa;

    a {
      color: #00ee99;
    }
  }
}