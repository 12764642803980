#signup-form {

  .content {
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input {
    margin-top: 12px;
  }

  .cancel {
    font-size: 11px;
    cursor: pointer;
  }

  .send {
    padding: 8px 12px;
  }
}