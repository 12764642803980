@import '../../styles/responsive.scss';

.tags {
  .list {
    display: flex;
    column-gap: 4px;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-top: -16px;

    @include for-phone-only {
      justify-content: center;
    }

    .tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 16px;
      margin-top: 16px;

      width: 280px;
      border-radius: 6px;
      color: #fff;
      padding: 8px;
      cursor: pointer;
      transition: all 400ms cubic-bezier(.25, .8, .25, 1);

      &:hover {
        opacity: 0.7;
      }

      .name {
        font-size: 18px;
        font-weight: bold;
        text-transform: lowercase;
      }

      .description {
        font-size: 13px;
      }

      .actions {

        a:not(:last-child) {
          margin-right: 8px;
        }

        i {
          padding: 4px;
          background-color: rgba(250, 250, 250, 0.2);
          border-radius: 4px;
          color: #fff;
          font-size: 20px;

          transition: opacity 500ms ease-in;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}