#tag-selector {

  .content {
    display: flex;
    column-gap: 4px;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-top: -8px;

    .tag {
      color: #fff;
      border-radius: 4px;
      padding: 4px 8px;
      margin-left: 8px;
      margin-top: 8px;
      cursor: pointer;
    }

  }
}

.wall {}