.login-form {
  text-align: center;


  #banner {
    text-decoration: none;
    font-weight: 700;
    font-size: 60px;
    color: #0e9;
    margin-bottom: 0px;
  }

  .description {
    color: #b7b7b7;
    font-size: 12px;
    margin-bottom: 24px;
  }

  .field {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    i {
      // color: #b7b7b7;
      // text-align: left;
      margin-right: 10px;
    }

    input {
      outline: none;
      border: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px 8px;
      border-radius: 8px;
      font-size: 14px;
      font-family: 'Rubik';
      width: 200px;
      transition: all 400ms cubic-bezier(.25, .8, .25, 1);

      &:focus,
      &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-color: #fff;
      }
    }
  }

  button {
    margin-top: 16px;
    margin-bottom: 8px;
  }
}