@import "../../styles/responsive.scss";

#header {
  grid-area: header;
  border-bottom: 1px solid #e1e1e1;
  padding: 16px 64px;
  display: flex;
  justify-content: space-between;

  @include for-phone-only {
    padding: 16px;
  }


  .logo {
    text-decoration: none;
    font-weight: 700;
    font-size: 28px;
    color: #0e9;
    margin-right: 8px;
  }

  .description {
    color: #b7b7b7;
    font-size: 12px;
    margin-bottom: 6px;
    // margin: 0px 0px 6px 8px;
    display: none;
    vertical-align: middle;

    @include for-tablet-portrait-up {
      display: inline-block;
    }
  }

  .menu {
    display: flex;

    .item {
      text-decoration: none;
      font-weight: 400;
      color: #000;

      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}